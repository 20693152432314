export default {
  LOGIN_PAGE_USER_IS_LOGGED_IN: 'LOGIN_PAGE_USER_IS_LOGGED_IN',
  LOGIN_PAGE_USER_IS_LOGGED_OUT: 'LOGIN_PAGE_USER_IS_LOGGED_OUT',
  LOGIN_PAGE_UPDATE_FORM: 'LOGIN_PAGE_UPDATE_FORM',
  LOGIN_PAGE_INVALID_FORM: 'LOGIN_PAGE_INVALID_FORM',
  LOGIN_PAGE_VALID_FORM: 'LOGIN_PAGE_VALID_FORM',
  LOGIN_PAGE_FORM_REQUEST_SUBMITTED: 'LOGIN_PAGE_FORM_REQUEST_SUBMITTED',
  LOGIN_PAGE_FORM_REQUEST_COMPLETE: 'LOGIN_PAGE_FORM_REQUEST_COMPLETE',
  LOGIN_PAGE_FORM_REQUEST_FAILED: 'LOGIN_PAGE_FORM_REQUEST_FAILED',
  LOGIN_PAGE_USER_DATA_REQUESTED: 'LOGIN_PAGE_USER_DATA_REQUESTED',
  LOGIN_PAGE_USER_DATA_REQUEST_COMPLETE: 'LOGIN_PAGE_USER_DATA_REQUEST_COMPLETE',
  LOGIN_PAGE_USER_DATA_REQUEST_FAILED: 'LOGIN_PAGE_USER_DATA_REQUEST_FAILED',
};