import ActionTypes from './sign-up-page-action-types';

const initialState = {
  form: {
    email: '',
    password: '',
    passwordConfirmation: '',
    displayName: '',
  },
  formErrors: {},
  formIsValid: true,
  isSubmitting: false,
  userCreated: false,
};

const signUpPageReducer = (state=initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SIGN_UP_PAGE_UPDATE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...payload.form,
        },
      };
    case ActionTypes.SIGN_UP_PAGE_INVALID_FORM:
      return {
        ...state,
        formIsValid: false,
        isSubmitting: false,
        formErrors: payload.formErrors,
      };
    case ActionTypes.SIGN_UP_PAGE_VALID_FORM:
      return {
        ...state,
        formIsValid: true,
        formErrors: {},
      };
    case ActionTypes.SIGN_UP_PAGE_FORM_REQUEST_SUBMITTED:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.SIGN_UP_PAGE_FORM_REQUEST_COMPLETE:
      return {
        ...state,
        isSubmitting: false,
        userCreated: true,
      };
    default:
      return initialState;
  }
};

export default signUpPageReducer;