import React from 'react';
import smallImage from './images/300.jpg';
import mediumImage from './images/768.jpg';
import largeImage from './images/1280.jpg';
import xlargeImage from './images/1920.jpg';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  image: {
    // objectFit: 'contain',
    // height: '100%',
    // width: '100%',
  },
}));

const SplashImage = () => {
  const classes = useStyles();

  const imagesSources = [
    `${smallImage} 300w`,
    `${mediumImage} 768w`,
    `${largeImage} 1280w`,
    `${xlargeImage} 1920w`
  ];

  return (
    <img className={classes.image}
         alt="Collaborative Group"
         src={smallImage}
         srcSet={imagesSources.join(', ')} />
  );
};

export default SplashImage;