import React, {useEffect, useState} from 'react';
import Loading from '../Loading/Loading';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import TestStepperCard from './components/TestStepperCard';
import Button from '@material-ui/core/Button';
import './components/test-stepper.css';

const TestStepper = ({
  isLoading,
  practiceTest,
  selectedAnswers,
  completeTest,
  fetchPracticeTest,
  updateTestAnswer,
}) => {

  const [currentQuestion, setCurrentQuestion] = useState(0);

  useEffect(() => {
    if (isLoading) {
      fetchPracticeTest();
    }
  });

  const getCurrentQuestion = () => {
    const { questions } = practiceTest;

    if (!practiceTest.questions) {
      return {};
    }

    const question = questions[currentQuestion];

    return {
      ...question,
      selectedAnswer: selectedAnswers[question.id] || "",
    };
  };

  const isLastQuestion = () => {
    const { questions } = practiceTest;
    return currentQuestion >= questions.length - 1 ;
  };

  const advanceQuestion = (modifier) => () => {
    const { questions } = practiceTest;
    let nextQuestion = currentQuestion + (1 * modifier);
    if(nextQuestion < 0) nextQuestion = 0;
    if(nextQuestion >= questions.length) nextQuestion = questions.length - 1;

    setCurrentQuestion(nextQuestion);
  };

  if (isLoading) {
    return <Loading />
  }

  return (
    <Paper className="test-stepper">
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={12} className="breadcrumbs">
          <Breadcrumbs aria-label="Breadcrumb">
            <Link color="inherit" to="/">
              Home
            </Link>
            <Link color="inherit" to="/explore">
              Explore
            </Link>
            <Link color="inherit" to="/overview/html5-applications">
              HTML5 Applications
            </Link>
            <Typography color="textPrimary">Practice Test</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Grid container alignItems="center" justify="center" >
            <Grid item xs={12} md={6}>
              <TestStepperCard
                {...getCurrentQuestion()}
                updateTestAnswer={updateTestAnswer}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          {
            <Button id="previous"
                    variant="contained"
                    color="primary"
                    onClick={advanceQuestion(-1)}
                    disabled={currentQuestion === 0}
                    className="prev-button"
            >
              Previous
            </Button>
          }
          { isLastQuestion()
              ? (<Button id="next"
                      variant="contained"
                      color="primary"
                      onClick={completeTest}
                      className="submit-button"
                >
                  Submit
                </Button>)
              : (<Button id="next"
                         variant="contained"
                         color="primary"
                         onClick={advanceQuestion(1)}
                         disabled={isLastQuestion()}
                         className="next-button"
                >
                  Next
                </Button>)
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TestStepper;