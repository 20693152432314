const noop = () => {};
/** Password expresion that requires:
 one lower case letter
 one upper case letter
 one digit
 6-13 length
 no spaces.
 **/
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{4,8}$/g;
const passwordValidationMessage = `Password must contain
  one lower case letter,
  one upper case letter,
  one digit,
  6-13 length,
  no spaces.
`;

const passwordConfirmationValidationMessage = 'Password and Password Confirmation must match.';

const validatePassword = (password) => {
  if(!passwordRegex.test(password)) {
    return passwordValidationMessage;
  }
};

const validatePasswordConfirmation = (passwordConfirmation, form) => {
  const { password } = form;
  if (password !== passwordConfirmation) {
    return passwordConfirmationValidationMessage;
  }
};

const VALIDATORS = {
  password: validatePassword,
  passwordConfirmation: validatePasswordConfirmation,
};

export const validateForm = (form) => {
  const fields = Object.keys(form);
  let formIsValid = true;
  const formErrors = fields.reduce((errs, field) => {
    const val = form[field];
    const validationMessage = (VALIDATORS[field] || noop)(val, form);

    if(validationMessage) {
      formIsValid = false;
      return {
        ...errs,
        [field]: validationMessage,
      };
    }

    return errs;
  }, {});

  return {
    formIsValid,
    formErrors,
  };
};

