import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CorrectAnswerIcon from '@material-ui/icons/Check';
import IncorrectAnswerIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme =>({
  correctAnswerIcon: {
    color: 'green',
  },
  incorrectAnswerIcon: {
    color: 'red',
  },
  correctChoice: {
    paddingLeft: theme.spacing(1),
    backgroundColor: '#A9DFBF',
  },
  incorrectChoice: {
    paddingLeft: theme.spacing(1),
    backgroundColor: '#F5B7B1',
  },
  neutralChoice: {
    paddingLeft: theme.spacing(1),
  },
}));

const renderHeader = (isCorrect, index, question, classes) => (
  <h3>
    {index + 1}.
    {question.question}
    {
      isCorrect
        ? <CorrectAnswerIcon className={classes.correctAnswerIcon} />
        : <IncorrectAnswerIcon className={classes.incorrectAnswerIcon} />
    }
  </h3>
);

const renderChoice = (selectedAnswer, answer, choices, key, index, classes) => {
  const choice = choices[key];
  let className = key === answer ? classes.correctChoice : classes.neutralChoice;

  if(selectedAnswer === key) {
    className = selectedAnswer === answer ? classes.correctChoice : classes.incorrectChoice;
  }

  return (
    <Grid container key={key}>
      <Grid item xs={12} md={5}>
        <div className={className}>{index + 1}.&nbsp;{choice}</div>
      </Grid>
    </Grid>
  );
};

const TestOverviewQuestion = ({
  index,
  question,
  selectedAnswer,
}) => {
  const classes = useStyles();
  const { choices, answer } = question;

  const isCorrect = selectedAnswer === answer;

  return (<Grid className="single-question" item xs={12}>
    { renderHeader(isCorrect, index, question, classes) }
    {
      Object.keys(choices).map((key, i) => (
        renderChoice(selectedAnswer, answer, choices, key, i, classes)
      ))
    }
  </Grid>);
};

export default TestOverviewQuestion;