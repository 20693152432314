import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TestOverviewQuestion from './components/TestOverviewQuestion';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import AuthGatekeeper from '../AuthGatekeeper/AuthGatekeeper';

const useStyles = makeStyles(theme =>({
  testOverviewPaper: {
    padding: theme.spacing(2),
  },
  header: {
    textAlign: 'center',
  },
  saveButton: {
    float: 'left',
  },
  resetButton: {
    float: 'right',
  },
}));

const renderScore = (selectedAnswers, questions) => {
  const totalQuestion = questions.length;

  const correctAnswers = Object.keys(selectedAnswers).reduce((total, questionId) => {
    const question =  questions.find(q => q.id === questionId);
    const selectedAnswer = selectedAnswers[questionId];
    if(question.answer === selectedAnswer) {
      return total + 1;
    }

    return total;
  }, 0);

  const percentage = Math.floor((correctAnswers/totalQuestion) * 100);

  return (<span> &nbsp;{correctAnswers}/{totalQuestion}({percentage}%)</span>)
};

const TestOverview = ({
  practiceTest,
  selectedAnswers,
  saveTest,
  resetTest,
}) => {

  const classes = useStyles();

  const { questions } = practiceTest;

  const getAnswerForQuestion = (question) => {
    const { id: questionId } = question;
    return selectedAnswers[questionId];
  };

  const handleSaveClick = () => {
    saveTest(selectedAnswers);
  };

  return (<Paper className={classes.testOverviewPaper}><Grid container justify="center" alignItems="center">
    <Grid item xs={12} className="breadcrumbs">
      <Breadcrumbs aria-label="Breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/explore">
          Explore
        </Link>
        <Link color="inherit" to="/overview/html5-applications">
          HTML5 Applications
        </Link>
        <Typography color="textPrimary">Test Overview</Typography>
      </Breadcrumbs>
    </Grid>
    <Grid item xs={12}>
      <h2 className={classes.header}>
        <u>Test Overview</u>
        {renderScore(selectedAnswers, questions)}
      </h2>
    </Grid>
    <AuthGatekeeper>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Button color="primary"
                    variant="contained"
                    size="large"
                    className={classes.saveButton}
                    onClick={handleSaveClick}
            >
              Save
            </Button>
            <Button variant="contained"
                    size="large"
                    className={classes.resetButton}
                    onClick={resetTest}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </AuthGatekeeper>
    { questions.map((question, i) =>
        (<TestOverviewQuestion question={question}
                               index={i}
                               key={i}
                               selectedAnswer={getAnswerForQuestion(question)}
        />))
    }
  </Grid></Paper>);
};

export default TestOverview;
