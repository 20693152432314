import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import BuildIcon from '@material-ui/icons/Build';
import SpellCheckIcon from '@material-ui/icons/Spellcheck';
import DirectionsIcon from '@material-ui/icons/Directions';
import SplashImage from '../SplashImage/SplashImage';
import {Link} from 'react-router-dom';
import UnauthGatekeeper from '../UnauthGatekeeper/UnauthGatekeeper';
import AuthGatekeeper from '../AuthGatekeeper/AuthGatekeeper';
import FeedbackButtonContainer from '../FeedbackButton/FeedbackButtonContainer';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 2),
    position: 'relative',
    zIndex: 1,
    minHeight: '120vh',
  },
  splashImageContainer: {
    maxHeight: '55vh',
    width: '100vw',
    maxWidth: '1280px',
    top: '-5vh',
    position: 'relative',
    overflow: 'hidden',
  },
  paper: {
    maxHeight: '350px',
    minHeight: '150px',
    height: '35vh',
    padding: '1em',
  },
  listItemLink: {
    textDecoration: 'underline',
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.splashImageContainer}>
        <SplashImage />
      </div>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h3">
                Free for All
              </Typography>
              <Typography component="p">
                Feel free to study as much as you want, free of charge without any signing in or signing up required.
                Signing up for free just provided additional learning tools customized for you.
              </Typography>
              <br />
              <Button component={ Link } to="/explore" variant="outlined" color="primary">
                Explore
              </Button>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h3">
                Referenced from the source
              </Typography>
              <Typography component="p">
                We back all our material by exact page number or chapter in a specific book or resource.
                You can rest assured that our material is accurate and if you ever find any discrepancies, our
                <i>Feedback</i> feature allows you to submit feedback which will be reviewed.
              </Typography>
            </Paper>
        </Grid>
        <AuthGatekeeper>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h3">
                Provide Feedback
              </Typography>
              <Typography component="p">
                You are now able to provide feedback!
                Feedback is a way to communicate with our team directly and request changes or
                suggest fixes.
                Look for the feedback button throughout the site or feel free to click it here:
              </Typography>
              <br />
              <FeedbackButtonContainer metaDescription="Feedback from Home page"/>
            </Paper>
          </Grid>
        </AuthGatekeeper>
      </Grid>
      <UnauthGatekeeper>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <Box component="h3"
                 fontSize={{ xs: 'h4.fontSize', md: 'h3.fontSize' }}
            >
              Sign up and experience more!
            </Box>
            <p>
              Would you like to contribute either content or submit feedback on content?
              Wondering how you practice test scores compare?  All you have to do is sign up and you
              will be provided additional benefits including:
            </p>
            <List component="ul" aria-label="Benefits of signing up">
              <ListItem>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Saving your practice test scores" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Ability to contribute to a topic or request a new one" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <SpellCheckIcon />
                </ListItemIcon>
                <ListItemText primary="Being able to provide feedback to fix things such as typos and misinformation" />
              </ListItem>
              <ListItemLink component={ Link } to="/future-plans" className={classes.listItemLink}>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText primary="And much more to come..." />
              </ListItemLink>
            </List>
          </Grid>
        </Grid>
      </UnauthGatekeeper>
    </div>
  );
};

export default Home;