import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import FeedbackButton from './components/FeedbackButton';
import { submitFeedback } from './state/feedback-button-actions';
import { FirebaseContext } from '../Firebase';
import AuthGatekeeper from '../AuthGatekeeper/AuthGatekeeper';

const mapStateToProps = (state) => state;

const mapDispatchToProps = dispatch => bindActionCreators({
  submitFeedback,
}, dispatch);

const FeedbackButtonContainer = props => (
  <FirebaseContext.Consumer>
    { firebase => (
      <AuthGatekeeper>
        <FeedbackButton {...props} firebase={firebase} />
      </AuthGatekeeper>
    )}
  </FirebaseContext.Consumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackButtonContainer);