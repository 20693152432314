import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import H3Wrapper from '../H3Wrapper/H3Wrapper';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme =>({
  container: {
    padding: theme.spacing(1),
  },
}));

const ComingSoonPage = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Grid container direction="column" alignItems="center" justify="space-around">
        <Grid item xs={12} md={8}>
          <H3Wrapper text="Coming Soon..."/>
          <p>
            The content you are trying to access is not currently available
            but there are plans for it to be added in the near future.
          </p>
        </Grid>
        <Grid item xs={12}>
          <Button component={Link} variant="contained" color="primary" to="/">Back to Home</Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ComingSoonPage;