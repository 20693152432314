import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const prodConfig = {
  apiKey: "AIzaSyCeyGy-hUNzRkSQfsYTosZzts23VKekUIk",
  authDomain: "cramstar-cf4a1.firebaseapp.com",
  databaseURL: "https://cramstar-cf4a1.firebaseio.com",
  projectId: "cramstar-cf4a1",
  storageBucket: "",
  messagingSenderId: "438831098717",
  appId: "1:438831098717:web:f1ac21ea0a96f7a8"
};

const devConfig = {
  apiKey: "AIzaSyBBKgNjflAeM4ZD9N_icIwnhDxNUXSh4k8",
  authDomain: "study-html5.firebaseapp.com",
  databaseURL: "https://study-html5.firebaseio.com",
  projectId: "study-html5",
  storageBucket: "",
  messagingSenderId: "652865749505",
  appId: "1:652865749505:web:1730e5dbbae6d9ef"
};

const config =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.database = app.database();
  }

  get currentUser() {
    return this.auth.currentUser;
  }

  checkIfUserIsAdmin() {
    return this.database.ref('adminUsers').once('value')
      .then(snapshot => {
        return snapshot.val().indexOf(this.currentUser.email) > -1;
      });
  }

  submitFeedback(metaDescription, userComment) {
    const feedbackKey = this.database.ref().child('feedback').push().key;
    const displayName = this.currentUser.displayName;
    const email = this.currentUser.email;

    const payload = {
      displayName,
      email,
      userComment,
      metaDescription,
      dateSubmitted: (new Date()).toJSON(),
    };

    return this.database.ref(`feedback/${feedbackKey}`).set(payload);
  }

  getFeedback() {
    return this.database.ref('/feedback').once('value')
      .then(snapshot => snapshot.val());
  };

  updateFeedback(id, feedback) {
    return this.database.ref(`/feedback/${id}`).set(feedback);
  }

  saveTestAnswers(selectedAnswers) {
    const uid = this.currentUser.uid;
    return this.database.ref(`/userData/${uid}/selectedAnswers`).set(selectedAnswers);
  }

  resetTestAnswers() {
    const uid = this.currentUser.uid;
    return this.database.ref(`/userData/${uid}/selectedAnswers`).set(null);
  }

  getUserData() {
    const uid = this.currentUser.uid;
    return this.database.ref(`/userData/${uid}`).once('value')
      .then(snapshot => snapshot.val());
  }

}

export default Firebase;
