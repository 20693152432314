import React, { Fragment } from 'react';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

const useStyles = makeStyles(theme =>({
  subList: {
    borderLeft: '1px solid black',
    marginLeft: '1.5em',
  },
}));

const FeedbackListItemText = ({ title, text }) => {
  return (
    <Fragment>
      <strong>{title}&nbsp;</strong>
      <span>{text.toString()}</span>
    </Fragment>
  );
};

const FeedbackCheckListItem = ({ id, feedback, classes, onCheckClicked }) => {
  const [open, setOpen] = React.useState(false);

  const {
    dateSubmitted,
    displayName,
    email,
    metaDescription,
    userComment,
    isReviewed
  } = feedback;

  const displayDate = (new Date(dateSubmitted)).toDateString();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleCheckClick = () => {
    onCheckClicked(id, !isReviewed)
  };

  return (
    <Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isReviewed}
            tabIndex={-1}
            disableRipple
            inputProps={{'aria-labelledby': id}}
            onClick={handleCheckClick}
          />
        </ListItemIcon>
        <ListItemText primary={userComment} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" className={classes.subList}>
          <ListItem button className={classes.nested}>
            <ListItemText primary={<FeedbackListItemText title='Submitted:' text={displayDate}/>} />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemText primary={<FeedbackListItemText title='Name:' text={displayName}/>} />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemText primary={<FeedbackListItemText title='Email:' text={email}/>} />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemText primary={<FeedbackListItemText title='Meta-Description:' text={metaDescription}/>} />
          </ListItem>
        </List>
      </Collapse>
    </Fragment>
  );
};

const FeedbackCheckList = ({
  feedback,
  onCheckClicked,
}) => {
  const classes = useStyles();
  const ids = Object.keys(feedback);

  return (
    <List className={classes.root}>
      { ids.length
        ? ids.map(id => (
            <FeedbackCheckListItem feedback={feedback[id]} id={id} classes={classes} key={id} onCheckClicked={onCheckClicked}/>
          ))
        : 'No Items to display'
      }
    </List>
  );
};

export default FeedbackCheckList;