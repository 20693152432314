import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';
import AdminGatekeeper from '../../AdminGatekeeper/AdminGatekeeper';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    borderRadius: 0,
    position: 'relative',
    zIndex: 2,
  },
  appHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  heading: {
    display: 'inline',
    paddingRight: '1em',
  },
  searchContainer: {
    display: 'inline',
  },
  headerLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  loginLink: {
    lineHeight: '2em',
    textDecoration: 'none',
  },
  iconButton: {
    padding: 5,
  },
  denseInput: {
    marginTop: theme.spacing(2),
    '& input': {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  menuIconContainer: {
    display: 'flex',
  },
  drawerContentContainer: {
    padding: theme.spacing(2),
    width: '300px',
  },
}));

const NavigationHeaderSmall = ({
  isLoggedIn,
  firebase,
  logout,
  resetIsAdmin,
}) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const displayName = isLoggedIn ? firebase.currentUser.displayName : '';

  const updateIsMenuOpen = value => () => {
    setIsMenuOpen(value);
  };

  const handleLogoutClick = () => {
    setIsMenuOpen(false);
    logout(firebase);
    resetIsAdmin();
  };


  return (
    <Fragment>
      <Paper className={classes.headerWrapper}>
        <header className={classes.appHeader}>
          <Grid container justify="space-between">
            <Grid item xs={2} className={classes.menuIconContainer}>
              <Button size="large" onClick={updateIsMenuOpen(!isMenuOpen)}>
                <MenuIcon />
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Box component="h1"
                     fontSize={{ xs: 'h5.fontSize' }}
                >
                  <Link to="/" className={classes.headerLink}>cramstar</Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </header>
      </Paper>
      <Drawer open={isMenuOpen} onClose={updateIsMenuOpen(false)}>
        <Grid container direction="column" justify="space-between" spacing={2} className={classes.drawerContentContainer}>
          { isLoggedIn ? (<Grid item>Welcome {displayName}!</Grid>) : null}
          {/*<Grid item className={classes.searchContainer}>*/}
            {/*<TextField*/}
              {/*className={classes.denseInput}*/}
              {/*margin="dense"*/}
              {/*variant="outlined"*/}
              {/*label="What are you studying?"*/}
              {/*InputProps={{*/}
                {/*endAdornment: (<InputAdornment position="end">*/}
                  {/*<IconButton className={classes.iconButton} aria-label="Search">*/}
                    {/*<SearchIcon />*/}
                  {/*</IconButton>*/}
                {/*</InputAdornment>),*/}
              {/*}}*/}
            {/*/>*/}
          {/*</Grid>*/}
          { !isLoggedIn
          ?([
            (<Grid item key="login">
              <Button fullWidth
                      onClick={updateIsMenuOpen(false)}
                      component={ Link } size="large" variant="contained" to="/login" className={classes.button} color="primary">
                Login
              </Button>
            </Grid>),
            (<Grid item key="signUp">
            <Button component={ Link }
                    onClick={updateIsMenuOpen(false)}
                    fullWidth variant="outlined" to="/sign-up" className={classes.button} color="primary">
              Sign Up
            </Button>
            </Grid>)
          ])
          : ([
              (
                <AdminGatekeeper LoadingComponent={null} key="admin" shouldRedirect={false}>
                  <Button fullWidth component={ Link }
                          onClick={updateIsMenuOpen(false)}
                          variant="outlined" size="large" to="/admin" className={classes.button} color="primary">
                    Admin
                  </Button>
                </AdminGatekeeper>
              ),
              (<Button key="logout" fullWidth size="large" onClick={handleLogoutClick} className={classes.button} color="primary">
                Logout
              </Button>),
              ])
          }
        </Grid>
      </Drawer>
    </Fragment>
  );
};

export default NavigationHeaderSmall;
