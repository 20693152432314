import ActionTypes from './practice-test-action-types';
import { getPracticeTest } from '../../../../api/question';
import { requestUserData } from '../../../LoginPage/state/login-page-actions';

export const fetchPracticeTest = () => dispatch => {
  dispatch(practiceTestRequested());

  return getPracticeTest()
    .then(practiceTest => dispatch(practiceTestRequestSucceeded(practiceTest)))
    .catch(error => dispatch(practiceTestRequestFailed(error)))
};

export const practiceTestRequested = () => ({
  type: ActionTypes.PRACTICE_TEST_REQUESTED,
});

export const practiceTestRequestSucceeded = practiceTest => ({
  type: ActionTypes.PRACTICE_TEST_REQUEST_SUCCEEDED,
  payload: {
    practiceTest,
  },
});

export const practiceTestRequestFailed = () => ({
  type: ActionTypes.PRACTICE_TEST_REQUEST_FAILED,
});

export const updateTestAnswer = (questionId, answer) => {
  return {
    type: ActionTypes.PRACTICE_TEST_UPDATE_ANSWER,
    payload: {
      questionId,
      answer,
    },
  };
};

export const completeTest = () => ({
  type: ActionTypes.PRACTICE_TEST_COMPLETE_TEST,
});


export const saveTest = (firebase, selectedAnswers) => dispatch => {
  return firebase.saveTestAnswers(selectedAnswers)
    .then(() => dispatch(({
      type: ActionTypes.PRACTICE_TEST_SAVE_TEST,
    })));
};

export const resetTest = firebase => dispatch => (
  firebase.resetTestAnswers()
    .then(() => dispatch(requestUserData(firebase)))
    .then(() => dispatch(practiceTestReset()))
);

export const practiceTestReset = () => ({
  type: ActionTypes.PRACTICE_TEST_RESET_TEST,
});

export const loadSavedTest = (selectedAnswers) => ({
  type: ActionTypes.PRACTICE_TEST_LOAD_SAVED_TEST,
  payload: { selectedAnswers },
});


