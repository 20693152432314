import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Redirect} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
  header: {
    textAlign: 'center',
    textDecoration: 'underline',
  },
  errorsContainer: {
    color: theme.palette.secondary.main,
  },
  errorsHeader: {
    textDecoration: 'underline',
  },
}));

const SignUpPage = ({
  signUp,
  updateForm,
  form,
  formErrors,
  formIsValid,
  isSubmitting,
  firebase,
  validateAndSubmit,
  userCreated,
}) => {
  const classes = useStyles();

  const handleChange = (name) => (event) => {
    updateForm(name, event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    validateAndSubmit(firebase, form);
  };

  const renderValidationErrors = () => {
    const errorFields = Object.keys(formErrors);
    return (<Grid container direction="column" className={classes.errorsContainer}>
      <Typography variant="h5" component="h5" className={classes.errorsHeader}>
        Errors
      </Typography>
      <ul>
      { errorFields.map(field => (
        <li key={field}>{formErrors[field]}</li>
        ))
      }
      </ul>
    </Grid>);
  };

  if(userCreated) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container alignContent="center" justify="center">
      <Grid item xs={12} sm={8} md={6}>
        <Paper className={classes.paper}>
          {
            isSubmitting && (
              <LinearProgress />
            )
          }
          <Typography variant="h4" component="h3" className={classes.header}>
            Sign Up
          </Typography>
          { !formIsValid && renderValidationErrors() }
          <form>
            <TextField
              label="Name"
              className={classes.textField}
              value={form.displayName}
              onChange={handleChange("displayName")}
              margin="normal"
              fullWidth
              required
              error={!!formErrors.displayName}
            />
            <TextField
              label="Email"
              className={classes.textField}
              value={form.email}
              onChange={handleChange("email")}
              margin="normal"
              type="email"
              fullWidth
              required
              error={!!formErrors.email}
            />
            <TextField
              label="Password"
              className={classes.textField}
              onChange={handleChange("password")}
              value={form.password}
              margin="normal"
              type="password"
              fullWidth
              required
              error={!!formErrors.password}
            />
            <TextField
              label="Confirm Password"
              className={classes.textField}
              onChange={handleChange("passwordConfirmation")}
              value={form.passwordConfirmation}
              margin="normal"
              type="password"
              fullWidth
              required
              error={!!formErrors.passwordConfirmation}
            />
            <Grid container direction="row-reverse" className={classes.buttonContainer}>
              <Button variant="contained"
                      color="primary"
                      onClick={handleFormSubmit}
                      disabled={isSubmitting}
              >
                Sign Up
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SignUpPage;