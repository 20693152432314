import React, { Component } from 'react';
import shuffle from '../../../../utils/shuffle';
import FlashCard from './FlashCard';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import './flash-card-carousel.css';
import Loading from '../../../Loading/Loading';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

class FlashCardCarousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      questionOrder: null,
      currentQuestion: null,
    };
  }

  shuffleQuesions() {
    const { questions } = this.props;
    const indices = questions.map((q, i) => i);
    const questionOrder = shuffle(indices);
    this.setState({
      questionOrder,
      currentQuestion: 0,
    })
  }

  componentDidMount() {
    const {
      isLoading,
      fetchQuestions,
    } = this.props;

    if (isLoading) {
      fetchQuestions();
    } else {
      this.shuffleQuesions();
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;
    if ((prevProps.isLoading !== isLoading) && !isLoading) {
      this.shuffleQuesions();
    }
  }

  getCurrentQuestion() {
    const { questions } = this.props;
    const { questionOrder, currentQuestion } = this.state;

    if (questionOrder === null || currentQuestion === null) {
      return {};
    }

    return questions[questionOrder[currentQuestion]];
  }

  get isLastQuestion() {
    const { questions } = this.props;
    const { currentQuestion } = this.state;
    return currentQuestion >= questions.length - 1 ;
  };

  advanceQuestion = (modifier) => () => {
    const { questions } = this.props;
    let { currentQuestion } = this.state;
    currentQuestion+= (1 * modifier);
    if(currentQuestion < 0) currentQuestion = 0;
    if(currentQuestion >= questions.length) currentQuestion = questions.length - 1;

    this.setState({
      currentQuestion,
    });
  };

  render() {
    const { isLoading } = this.props;
    const { currentQuestion } = this.state;

    if (isLoading) return (<Loading />);

    return (<Grid container direction="row" alignItems="center" justify="center" className="flash-card-carousel">
      <Grid item xs={12} className="breadcrumbs">
        <Breadcrumbs aria-label="Breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/explore">
            Explore
          </Link>
          <Link color="inherit" to="/overview/html5-applications">
            HTML5 Applications
          </Link>
          <Typography color="textPrimary">Flash Cards</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center" >
          <Grid item xs={12} md={6}>
            <FlashCard {...this.getCurrentQuestion()} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        {
          <Button id="previous"
            variant="contained"
            color="primary"
            onClick={this.advanceQuestion(-1)}
            disabled={currentQuestion === 0}
            className="prev-button"
          >
            Previous
          </Button>
        }
        {
          <Button id="next"
                  variant="contained"
                  color="primary"
                  onClick={this.advanceQuestion(1)}
                  disabled={this.isLastQuestion}
                  className="next-button"
          >
            Next
          </Button>
        }
      </Grid>
    </Grid>)
  }
}

export default FlashCardCarousel;