import ActionTypes from './flash-cards-action-types';

const initialState = {
  questions: [],
  isLoading: true,
};

const flashCardsReducer = (state=initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case ActionTypes.QUESTIONS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        questions: payload.questions,
      };
    case ActionTypes.QUESTIONS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.QUESTIONS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default flashCardsReducer;
