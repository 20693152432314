import ActionTypes from './practice-test-action-types';

const initialState = {
  practiceTest: {},
  isLoading: true,
  selectedAnswers: {},
  isComplete: false,
};

const practiceTestReducer = (state=initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case ActionTypes.PRACTICE_TEST_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        practiceTest: payload.practiceTest,
      };
    case ActionTypes.PRACTICE_TEST_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.PRACTICE_TEST_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.PRACTICE_TEST_UPDATE_ANSWER:
      return {
        ...state,
        isLoading: false,
        selectedAnswers: {
          ...state.selectedAnswers,
          [payload.questionId]: payload.answer,
        }
      };
    case ActionTypes.PRACTICE_TEST_COMPLETE_TEST:
      return {
        ...state,
        isComplete: true,
      };
    case ActionTypes.PRACTICE_TEST_RESET_TEST:
      return {
        ...state,
        isComplete: false,
        selectedAnswers: {},
      };
    case ActionTypes.PRACTICE_TEST_SAVE_TEST:
      return {
        ...state,
        isComplete: true,
      };
    case ActionTypes.PRACTICE_TEST_LOAD_SAVED_TEST:
      return {
        ...state,
        selectedAnswers: payload.selectedAnswers,
        isComplete: true,
      };
    default:
      return state;
  }
};

export default practiceTestReducer;
