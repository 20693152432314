import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import H3Wrapper from '../H3Wrapper/H3Wrapper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const useStyles = makeStyles(theme =>({
  overviewPageContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
  paper: {
    maxHeight: '350px',
    minHeight: '150px',
    height: '25vh',
    padding: '1em',
  },
  cardLink: {
    textDecoration: 'none',
  },
  breadCrumbContainer: {
    display: 'flex',
    width: '100%',
  },
}));

const OverviewPageContainer = ({
  contentTitle,
  contentDescription,
  subpath,
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.overviewPageContainer}
    >
      <Grid container
            direction="column"
            alignItems="center"
            justify="center"
      >
        <Grid item xs={12} className={classes.breadCrumbContainer}>
          <Breadcrumbs aria-label="Breadcrumb">
            <Link color="inherit" to="/">
              Home
            </Link>
            <Link color="inherit" to="/explore">
              Explore
            </Link>
            <Typography color="textPrimary">HTML5 Applications</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={10}>
          <H3Wrapper text={contentTitle} />
          <p dangerouslySetInnerHTML={{ __html: contentDescription }}>
          </p>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item xs={12} sm={5} lg={4} xl={3}>
              <Link to={`/${subpath}/single-page`} className={classes.cardLink}>
                <Paper className={classes.paper}>
                  <Typography variant="h5" component="h3">
                    Questions Cheatsheet
                  </Typography>
                  <Typography component="p">
                    This page will display a list of all questions and answers.
                    It is a good page to print out and review.
                  </Typography>
                </Paper>
              </Link>
            </Grid>
            <Grid item xs={12} sm={5} lg={4} xl={3}>
              <Link to={`/${subpath}/flash-cards`} className={classes.cardLink}>
                <Paper className={classes.paper}>
                  <Typography variant="h5" component="h3">
                    Flash Cards
                  </Typography>
                  <Typography component="p">
                    Study vocab and test objects in this flash card style page.
                    You will be able to enter your answers and compare to the actual answer.
                  </Typography>
                </Paper>
              </Link>
            </Grid>
            <Grid item xs={12} sm={5} lg={4} xl={3}>
              <Link to={`/${subpath}/practice-test`} className={classes.cardLink}>
                <Paper className={classes.paper}>
                  <Typography variant="h5" component="h3">
                    Practice Test
                  </Typography>
                  <Typography component="p">
                    Test your knowledge with this practice test.
                  </Typography>
                </Paper>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
};

export default OverviewPageContainer;
