import React, { Fragment, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import { checkIfUserIsAdmin } from './state/admin-gatekeeper-actions';
import { FirebaseContext } from '../Firebase';
import {connect} from 'react-redux';
import Loading from '../Loading/Loading';
import {Redirect} from 'react-router-dom';

const mapStateToProps = ({ adminGatekeeperState }) => adminGatekeeperState;

const mapDispatchToProps = dispatch => bindActionCreators({
  checkIfUserIsAdmin,
}, dispatch);

const AdminGatekeeper = props => {
  const {
    firebase,
    isAdminUser,
    isLoading,
    hasDoneAdminCheck,
    checkIfUserIsAdmin,
    children,
    LoadingComponent,
    shouldRedirect = true,
  } = props;

  useEffect(() => {
    if(!hasDoneAdminCheck && !isLoading) {
      checkIfUserIsAdmin(firebase);
    }
  });

  if(!hasDoneAdminCheck || isLoading) {
    return LoadingComponent === undefined ? (<Loading />) : LoadingComponent;
  }

  if(hasDoneAdminCheck && !isAdminUser) {
    return shouldRedirect ? (<Redirect to='/' />) : null;
  }

  return children;
};

const AdminGatekeeperWithFirebase = props => {
  return (
    <FirebaseContext.Consumer>
      {firebase =>
        <AdminGatekeeper {...props} firebase={firebase} />
      }
    </FirebaseContext.Consumer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminGatekeeperWithFirebase);
