import {createStore, applyMiddleware, compose} from 'redux';
import * as allReducers from './reducers';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
);

const store = createStore(
  combineReducers(allReducers),
  enhancer,
);

export default store;