import ActionTypes from './flash-cards-action-types';
import { getQuestions } from '../../../../api/question';

export const fetchQuestions = () => dispatch => {
  dispatch(questionsRequested());

  getQuestions()
    .then(questions => dispatch(questionsRequestSucceeded(questions)))
    .catch(error => dispatch(questionsRequestFailed(error)))
};

export const questionsRequested = () => ({
  type: ActionTypes.QUESTIONS_REQUESTED,
});

export const questionsRequestSucceeded = questions => ({
  type: ActionTypes.QUESTIONS_REQUEST_SUCCEEDED,
  payload: {
    questions,
  },
});

export const questionsRequestFailed = () => ({
  type: ActionTypes.QUESTIONS_REQUEST_FAILED,
});

