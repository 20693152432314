import React from 'react';
import {connect} from 'react-redux';
import { userIsLoggedIn, requestUserData } from '../LoginPage/state/login-page-actions';
import {bindActionCreators} from 'redux';
import {withFirebase} from '../Firebase';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  userIsLoggedIn,
  requestUserData,
}, dispatch);

const AutoSignInUserContainer = ({
  userIsLoggedIn,
  requestUserData,
  firebase,
}) => {
  userIsLoggedIn();
  requestUserData(firebase); // @TODO: may cause async issues
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(AutoSignInUserContainer));
