import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { fetchQuestions } from '../FlashCards/state/flash-cards-actions';
import QuestionsSinglePage from './components/QuestionsSinglePage/QuestionsSinglePage';

const mapStateToProps = ({ flashCardsState }) => ({
  ...flashCardsState,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchQuestions,
}, dispatch);

const SinglePageContainer = (props) => (
  <QuestionsSinglePage {...props} />
);

export default connect(mapStateToProps, mapDispatchToProps)(SinglePageContainer);
