import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AdminGatekeeper from '../../AdminGatekeeper/AdminGatekeeper';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    borderRadius: 0,
    position: 'relative',
    zIndex: 2,
  },
  appHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  heading: {
    display: 'inline',
    paddingRight: '1em',
  },
  searchContainer: {
    display: 'inline',
  },
  headerLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  loginLink: {
    lineHeight: '2em',
    textDecoration: 'none',
  },
  iconButton: {
    padding: 5,
  },
  denseInput: {
    marginTop: theme.spacing(2),
    '& input': {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
}));

const UserTools = ({
  displayName,
  classes,
  onLogoutClick,
}) => {
  return (
    <Grid container alignItems="center" justify="space-between" spacing={1}>
      <Grid item>Welcome {displayName}! </Grid>
      <Grid item>
        <AdminGatekeeper LoadingComponent={null}>
          <Button component={ Link } variant="outlined" size="small" to="/admin" className={classes.button} color="primary">
            Admin
          </Button>
        </AdminGatekeeper>
      </Grid>
      <Grid item>
        <Button size="small" color="primary" onClick={onLogoutClick}>logout</Button>
      </Grid>
    </Grid>
  );
};

const NavigationHeader = ({
  firebase,
  isLoggedIn,
  logout,
  resetIsAdmin,
}) => {
  const classes = useStyles();
  const displayName = isLoggedIn ? firebase.currentUser.displayName : '';

  const handleLogoutClick = () => {
    logout(firebase);
    resetIsAdmin();
  };

  return (
    <Paper className={classes.headerWrapper}>
      <header className={classes.appHeader}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container>
              <h1 className={classes.heading}>
                <Link to="/" className={classes.headerLink}>cramstar</Link>
              </h1>
              {/*<Grid item className={classes.searchContainer}>*/}
                {/*<TextField*/}
                  {/*className={classes.denseInput}*/}
                  {/*margin="dense"*/}
                  {/*variant="outlined"*/}
                  {/*label="What are you studying?"*/}
                  {/*InputProps={{*/}
                    {/*endAdornment: (<InputAdornment position="end">*/}
                      {/*<IconButton className={classes.iconButton} aria-label="Search">*/}
                        {/*<SearchIcon />*/}
                      {/*</IconButton>*/}
                    {/*</InputAdornment>),*/}
                  {/*}}*/}
                {/*/>*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
          <Grid item>
            {
              isLoggedIn
                ? (<UserTools displayName={displayName} classes={classes} onLogoutClick={handleLogoutClick}/>)
              : (
                  <Grid container spacing={3}>
                    <Grid item>
                      <Link to="/login" className={classes.loginLink}>Login</Link>
                    </Grid>
                    <Grid item>
                      <Button component={ Link } variant="outlined" size="small" to="/sign-up" className={classes.button} color="primary">
                        Sign Up
                      </Button>
                    </Grid>
                  </Grid>
                )
            }
          </Grid>
        </Grid>
      </header>
    </Paper>
  );
};

export default NavigationHeader;
