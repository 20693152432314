import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginPage from './components/LoginPage';
import { updateForm, login } from './state/login-page-actions';
import  { FirebaseContext } from '../Firebase';

const mapStateToProps = ({ loginPageState }) => ({ ...loginPageState });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm,
  login,
}, dispatch);

const SignUpPageContainer = (props) => {
  return (
    <FirebaseContext.Consumer>
      {firebase => (
        <LoginPage {...props} firebase={firebase} />
      )}
    </FirebaseContext.Consumer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPageContainer);