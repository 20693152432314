import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SignUpPage from './components/SignUpPage';
import { signUp, updateForm, validateAndSubmit } from './state/sign-up-page-actions';
import  { FirebaseContext } from '../Firebase';

const mapStateToProps = ({ signUpPageState }) => ({ ...signUpPageState });

const mapDispatchToProps = dispatch => bindActionCreators({
  signUp,
  updateForm,
  validateAndSubmit,
}, dispatch);

const SignUpPageContainer = (props) => {
  return (
    <FirebaseContext.Consumer>
      {firebase => (
        <SignUpPage {...props} firebase={firebase} />
      )}
    </FirebaseContext.Consumer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPageContainer);