export default {
  PRACTICE_TEST_REQUESTED: 'PRACTICE_TEST_REQUESTED',
  PRACTICE_TEST_REQUEST_SUCCEEDED: 'PRACTICE_TEST_REQUEST_SUCCEEDED',
  PRACTICE_TEST_REQUEST_FAILED: 'PRACTICE_TEST_REQUEST_SUCCEEDED',
  PRACTICE_TEST_UPDATE_ANSWER: 'PRACTICE_TEST_UPDATE_ANSWER',
  PRACTICE_TEST_COMPLETE_TEST: 'PRACTICE_TEST_COMPLETE_TEST',
  PRACTICE_TEST_RESET_TEST: 'PRACTICE_TEST_RESET_TEST',
  PRACTICE_TEST_SAVE_TEST: 'PRACTICE_TEST_SAVE_TEST',
  PRACTICE_TEST_LOAD_SAVED_TEST: 'PRACTICE_TEST_LOAD_SAVED_TEST',
}
