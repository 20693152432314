import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import './questions-single-page.css';
import Loading from '../../../../Loading/Loading';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FeedbackButtonContainer from '../../../../FeedbackButton/FeedbackButtonContainer';

const byPageNumber = (a, b) => {
  return parseInt(a.page) - parseInt(b.page);
};

const SingleQuestion = ({ question, index }) => (<Grid className="single-question" item xs={12}>
    <h3>{index + 1}. {question.question}</h3>
    <p>{question.answer}&nbsp;<sub><em>page. {question.page}</em></sub></p>
</Grid>);

class QuestionsSinglePage extends Component {

  componentDidMount() {
    const {
      isLoading,
      fetchQuestions,
    } = this.props;

    if (isLoading) {
      fetchQuestions();
    }
  }
  render() {
    const { questions, isLoading } = this.props;

    if(isLoading) return <Loading />;

    return (<Paper className="all-questions"><Grid container justify="center" alignItems="center">
      <Grid item xs={12} className="breadcrumbs">
        <Breadcrumbs aria-label="Breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/explore">
            Explore
          </Link>
          <Link color="inherit" to="/overview/html5-applications">
            HTML5 Applications
          </Link>
          <Typography color="textPrimary">Cheatsheet</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <h2 className="all-questions-header"><u>All Questions</u></h2>
        <FeedbackButtonContainer metaDescription="Feedback for the All Questions page" />
      </Grid>
      { questions
          .sort(byPageNumber)
          .map((question, i) => <SingleQuestion question={question} index={i} key={i} />)
      }
    </Grid></Paper>);
  }
}

export default QuestionsSinglePage;