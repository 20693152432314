import ActionTypes from './login-page-action-types';
import { resetIsAdmin } from '../../AdminGatekeeper/state/admin-gatekeeper-actions';
import { practiceTestReset } from '../../HTML5Applications/PracticeTest/state/practice-test-actions';
import { checkIfUserIsAdmin } from '../../AdminGatekeeper/state/admin-gatekeeper-actions';

export const userIsLoggedIn = () => ({
  type: ActionTypes.LOGIN_PAGE_USER_IS_LOGGED_IN,
});

export const updateForm = (name, value) => ({
  type: ActionTypes.LOGIN_PAGE_UPDATE_FORM,
  payload: {
    form: {
      [name]: value,
    }
  },
});

export const login = (firebase, form) => dispatch => {
    dispatch(validForm());
    dispatch(submitForm(firebase, form))
};

export const validForm = () => ({
  type: ActionTypes.LOGIN_PAGE_VALID_FORM,
});

export const invalidForm = (formErrors) => ({
  type: ActionTypes.LOGIN_PAGE_INVALID_FORM,
  payload: {
    formErrors,
  },
});

export const submitForm = (firebase, form) => dispatch => {
  const {
    email,
    password,
  } = form;

  dispatch(formRequestSubmitted());

  firebase.auth.signInWithEmailAndPassword(email, password)
    .then(() => {
      dispatch(resetIsAdmin());
      return dispatch(checkIfUserIsAdmin(firebase));
    })
    .then(() => {
      dispatch(formRequestComplete());
      return dispatch(requestUserData(firebase));
    })
    .catch(error => {
      dispatch(invalidForm({
        email:  error.message,
      }))
    });
};

export const formRequestSubmitted = () => ({
  type: ActionTypes.LOGIN_PAGE_FORM_REQUEST_SUBMITTED,
});

export const formRequestComplete = () => ({
  type: ActionTypes.LOGIN_PAGE_FORM_REQUEST_COMPLETE,
});

export const formRequestFailed = () => ({
  type: ActionTypes.LOGIN_PAGE_FORM_REQUEST_FAILED,
});

export const logout = (firebase) => dispatch => (
    firebase.auth.signOut().then(() => {
      dispatch(userIsLoggedOut());
      dispatch(practiceTestReset())
    })
);

export const userIsLoggedOut = () => ({
  type: ActionTypes.LOGIN_PAGE_USER_IS_LOGGED_OUT,
});

export const requestUserData = (firebase) => dispatch => {
  dispatch(userDataRequested());
  return firebase.getUserData()
    .then(userData => dispatch(userDataRequestComplete(userData)))
    .catch(error => dispatch(userDataRequestFailed(error)))
};

export const userDataRequested = () => ({
  type: ActionTypes.LOGIN_PAGE_USER_DATA_REQUESTED,
});

export const userDataRequestComplete = (userData) => ({
  type: ActionTypes.LOGIN_PAGE_USER_DATA_REQUEST_COMPLETE,
  payload: { userData },
});

export const userDataRequestFailed = (error) => ({
  type: ActionTypes.LOGIN_PAGE_USER_DATA_REQUEST_FAILED,
  error: { error },
});
