import ActionTypes from './sign-up-page-action-types';
import { userIsLoggedIn } from '../../LoginPage/state/login-page-actions';
import { validateForm } from '../helpers/validators';

export const signUp = (email, password) => {
  return {
    type: ActionTypes.SIGN_UP_PAGE_SIGN_UP,
  };
};

export const updateForm = (name, value) => ({
    type: ActionTypes.SIGN_UP_PAGE_UPDATE_FORM,
    payload: {
      form: {
        [name]: value,
      }
    },
});

export const validateAndSubmit = (firebase, form) => dispatch => {
  const {
    formIsValid,
    formErrors,
  } = validateForm(form);

  if (!formIsValid) {
    dispatch(invalidForm(formErrors))
  } else {
    dispatch(validForm());
    dispatch(submitForm(firebase, form))
  }
};

export const validForm = () => ({
  type: ActionTypes.SIGN_UP_PAGE_VALID_FORM,
});

export const invalidForm = (formErrors) => ({
  type: ActionTypes.SIGN_UP_PAGE_INVALID_FORM,
  payload: {
    formErrors,
  },
});

export const submitForm = (firebase, form) => dispatch => {
  const {
    email,
    password,
    displayName,
  } = form;

  dispatch(formRequestSubmitted());

  firebase.auth.createUserWithEmailAndPassword(email, password)
    .then(() => firebase.currentUser.updateProfile({
      displayName,
    }))
    .then(() => {
      dispatch(formRequestComplete());
      dispatch(userIsLoggedIn());
    })
    .catch(error => {
      dispatch(invalidForm({
        email:  error.message,
      }))
    });
};

export const formRequestSubmitted = () => ({
  type: ActionTypes.SIGN_UP_PAGE_FORM_REQUEST_SUBMITTED,
});

export const formRequestComplete = () => ({
  type: ActionTypes.SIGN_UP_PAGE_FORM_REQUEST_COMPLETE,
});
