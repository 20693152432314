import React from 'react';
import {bindActionCreators} from 'redux';
import { FirebaseContext } from '../Firebase';
import {connect} from 'react-redux';
import AdminGatekeeper from '../AdminGatekeeper/AdminGatekeeper';
import FeedbackList from './components/FeedbackList';
import { getFeedback, updateFeedback } from './state/admin-page-actions';

const mapStateToProps = ({ adminPageState }) => ({ ...adminPageState });

const mapDispatchToProps = dispatch => bindActionCreators({
  getFeedback,
  updateFeedback,
}, dispatch);

const AdminPageContainer = props => (
  <AdminGatekeeper>
    <FirebaseContext.Consumer>
      {firebase => <FeedbackList firebase={firebase} {...props} />}
    </FirebaseContext.Consumer>
  </AdminGatekeeper>
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminPageContainer);
