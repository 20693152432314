import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { fetchQuestions } from './state/flash-cards-actions';
import FlashCardCarousel from './components/FlashCardCarousel';

const mapStateToProps = ({ flashCardsState }) => ({
  ...flashCardsState,
});


const mapDispatchToProps = dispatch => bindActionCreators({
  fetchQuestions,
}, dispatch);

const FlashCardsContainer = props => (
    <FlashCardCarousel {...props} />
);

export default connect(mapStateToProps, mapDispatchToProps)(FlashCardsContainer);