import ActionTypes from './login-page-action-types';

const initialState = {
  form: {
    email: '',
    password: '',
  },
  formErrors: {},
  formIsValid: true,
  isSubmitting: false,
  isLoggedIn: false,
  userData: {},
  isUserDataLoaded: false,
};

const loginPageReducer = (state=initialState, action) => {
  const { payload } = action;

  switch(action.type) {
    case ActionTypes.LOGIN_PAGE_USER_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case ActionTypes.LOGIN_PAGE_UPDATE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...payload.form,
        },
      };
    case ActionTypes.LOGIN_PAGE_INVALID_FORM:
      return {
        ...state,
        formIsValid: false,
        isSubmitting: false,
        formErrors: payload.formErrors,
      };
    case ActionTypes.LOGIN_PAGE_VALID_FORM:
      return {
        ...state,
        formIsValid: true,
        formErrors: {},
      };
    case ActionTypes.LOGIN_PAGE_FORM_REQUEST_SUBMITTED:
      return {
        ...state,
        isSubmitting: true,
        isUserDataLoaded: false,
      };
    case ActionTypes.LOGIN_PAGE_FORM_REQUEST_COMPLETE:
      return {
        ...state,
        isSubmitting: false,
        isLoggedIn: true,
      };
    case ActionTypes.LOGIN_PAGE_FORM_REQUEST_FAILED:
      return {
        ...state,
        isUserDataLoaded: true,
      };
    case ActionTypes.LOGIN_PAGE_USER_IS_LOGGED_OUT:
      return {
        ...state,
        isLoggedIn: false,
        form: {
          email: '',
          password: '',
        },
        userData: {},
        isUserDataLoaded: false,
      };
    case ActionTypes.LOGIN_PAGE_USER_DATA_REQUESTED:
      return {
        ...state,
        isUserDataLoaded: false,
      };
    case ActionTypes.LOGIN_PAGE_USER_DATA_REQUEST_COMPLETE:
      return {
        ...state,
        userData: payload.userData,
        isUserDataLoaded: true,
      };
    default:
      return state;
  }
};

export default loginPageReducer;