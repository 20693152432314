import React from 'react';
import Box from '@material-ui/core/Box';

const H3Wrapper = ({ text }) => {
  return (
    <Box component="h3"
         fontSize={{ xs: 'h4.fontSize', md: 'h3.fontSize' }}
    >
      {text}
    </Box>
  );
};

export default H3Wrapper;