import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import FeedbackButtonContainer from '../../../FeedbackButton/FeedbackButtonContainer';

const useStyles = makeStyles(theme =>({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  feedbackContainer: {
    marginTop: theme.spacing(1),
  },
}));

const FlashCard = ({
  question,
  answer,
  page,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const feedbackMetaDescription = `Feedback for HTML5 Applications flash card question: '${question}'`;

  React.useEffect(() => setExpanded(false), [question]);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" component="h5">{question}</Typography>
      <ExpansionPanel expanded={expanded} onChange={handleChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Answer:</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {answer}
          </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
          <small><em>page {page}</em></small>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Grid container direction="row-reverse" className={classes.feedbackContainer}>
        <FeedbackButtonContainer metaDescription={feedbackMetaDescription} />
      </Grid>
    </Paper>
  );
};

export default FlashCard;