import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  explorerPageContainer: {
    marginTop: theme.spacing(2),
  },
  breadCrumbContainer: {
    display: 'flex',
    width: '100%',
    paddingLeft: theme.spacing(2),
  },
}));

const ExplorerPage = () => {
  const classes = useStyles();

  return (
    <Grid container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.explorerPageContainer}
    >
      <Grid item xs={12} className={classes.breadCrumbContainer}>
        <Breadcrumbs aria-label="Breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Explore</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Box component="h3"
             fontSize={{ xs: 'h4.fontSize', md: 'h3.fontSize' }}
        >
          Explore Our Study Materials
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="HTML5 Applications"
            >
              <Typography className={classes.heading}>HTML5 Applications</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                HTML5 is the latest iteration of HTML and empowers developers to create amazing platform
                platform independent applications.  The HTML5 family included HTML5, CSS3, and
                the latest JavaScript APIs and syntax.  This material is based on the book
                <em> HTML5 Application Development Fundamentals</em> which is the book used to study for
                the <em>MTA Exam 98-375</em>.
              </Typography>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
              <Button component={ Link } variant="outlined" color="primary" to="/overview/html5-applications">
                Overview
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ExplorerPage;