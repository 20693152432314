import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const FeedbackButton = ({
  firebase,
  metaDescription,
  submitFeedback,
}) => {
  const [open, setOpen] = useState(false);
  const [userComment, setUserComment] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUserComment('');
  };

  const handleChange = (event) => {
    setUserComment(event.target.value);
  };

  const handleSubmit = () => {
    submitFeedback(firebase, metaDescription, userComment);
    handleClose();
  };

  return (
    <div>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        Feedback
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Is there a problem with this content or do you have a suggestion?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Comments - Please be as specific as possible"
            rows="4"
            variant="outlined"
            onChange={handleChange}
            fullWidth
            multiline
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeedbackButton;