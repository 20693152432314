import ActionTypes from './admin-page-action-types';

const initialState = {
  feedbackIsLoaded: false,
  isLoading: false,
  feedback: {},
};

const updateFeedback = (state, payload) => {
  const { id, feedback:updatedFeedback } = payload;
  const { feedback } = state;
  const feedbackState = {
    ...feedback,
    [id]: updatedFeedback,
  };

  return {
    ...state,
    feedback: feedbackState,
  }
};

const adminPageReducer = (state=initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.ADMIN_PAGE_FEEDBACK_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.ADMIN_PAGE_FEEDBACK_REQUEST_COMPLETE:
      return {
        ...state,
        isLoading: false,
        feedbackIsLoaded: true,
        feedback: payload.feedback,
      };
    case ActionTypes.ADMIN_PAGE_FEEDBACK_UPDATE_SUBMITTED:
      return {
        ...state,
      };
    case ActionTypes.ADMIN_PAGE_FEEDBACK_UPDATE_COMPLETE:
      return updateFeedback(state, payload);
    default:
      return state;
  }
};

export default adminPageReducer;