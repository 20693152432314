import React from 'react';
import NavigationHeaderSmall from './components/NavigationHeaderSmall';
import { FirebaseContext } from '../Firebase';
import { connect } from 'react-redux';
import { logout } from '../LoginPage/state/login-page-actions';
import { resetIsAdmin } from '../AdminGatekeeper/state/admin-gatekeeper-actions';
import {bindActionCreators} from 'redux';

const mapStateToProps = ({ loginPageState }) => ({
  isLoggedIn: loginPageState.isLoggedIn,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  resetIsAdmin,
}, dispatch);

const NavigationHeaderSmallContainer = (props) => (
  <FirebaseContext.Consumer>
    { firebase => (
      <NavigationHeaderSmall {...props} firebase={firebase}/>
    )}
  </FirebaseContext.Consumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(NavigationHeaderSmallContainer);