import ActionTypes from './feedback-button-action-types';

export const submitFeedback = (firebase, metaDescription, userComment) => dispatch => {
  dispatch(feedbackRequestSubmitted());

  return firebase.submitFeedback(metaDescription, userComment).then(() => {
    dispatch(feedbackRequestCompleted());
  });
};

export const feedbackRequestSubmitted = () => ({
  type: ActionTypes.FEEDBACK_BUTTON_REQUEST_SUBMITTED,
});

export const feedbackRequestCompleted = () => ({
  type: ActionTypes.FEEDBACK_BUTTON_REQUEST_COMPLETED,
});
