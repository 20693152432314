import ActionTypes from './admin-gatekeeper-action-types';

export const checkIfUserIsAdmin = firebase => dispatch => {
  dispatch(adminUsersRequested());
  return firebase.checkIfUserIsAdmin()
    .then((isAdminUser) => dispatch(adminUsersRequestSucceeded(isAdminUser)))
    .catch(() => dispatch(adminUsersRequestFailed()));
};

export const adminUsersRequested = () => ({
  type: ActionTypes.ADMIN_GATEKEEPER_ADMIN_USERS_REQUESTED,
});

export const adminUsersRequestSucceeded = (isAdminUser) => ({
  type: ActionTypes.ADMIN_GATEKEEPER_ADMIN_USERS_REQUEST_SUCCEEDED,
  payload: {
    isAdminUser,
  },
});

export const adminUsersRequestFailed = () => ({
  type: ActionTypes.ADMIN_GATEKEEPER_ADMIN_USERS_REQUEST_FAILED,
});

export const resetIsAdmin = () => ({
  type: ActionTypes.ADMIN_GATEKEEPER_RESET,
});
