import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from '../Home/Home';
import store from '../../store/store';
import { Provider } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import ExplorerPage from '../ExplorePage/ExplorerPage';
import HTML5ApplicationsOverviewPage from '../HTML5Applications/HTML5ApplicationsOverviewPage';
import HTML5ApplicationsFlashCards from '../HTML5Applications/FlashCards/FlashCardsContainer';
import HTML5ApplicationsSinglePage from '../HTML5Applications/SinglePage/SinglePageContainer';
import HTML5ApplicationsPracticeTest from '../HTML5Applications/PracticeTest/PracticeTestContainer';
import './App.css';
import Footer from '../Footer/Footer';
import ComingSoonPage from '../ComingSoonPage/ComingSoonPage';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import SignUpPageContainer from '../SignUpPage/SignUpPageContainer';
import NavigationHeaderContainer from '../NavigationHeader/NavigationHeaderContainer';
import NavigationHeaderSmallContainer from '../NavigationHeaderSmall/NavigationHeaderSmallContainer';
import LoginPageContainer from '../LoginPage/LoginPageContainer';
import AdminPageContainer from '../AdminPage/AdminPageContainer';
import AuthGatekeeper from '../AuthGatekeeper/AuthGatekeeper';
import CurrentUserResolver from '../Firebase/components/CurrentUserResolver';
import AutoSignInUser from '../AutoSignInUser/AutoSignInUser';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="app">
          <Hidden mdUp>
            <NavigationHeaderSmallContainer />
          </Hidden>
          <Hidden smDown>
            <NavigationHeaderContainer />
          </Hidden>
          <div className="app-content-container">
            <Route exact path="/" component={Home} />
            <Route exact path="/explore" component={ExplorerPage} />
            <Route exact path="/overview/html5-applications" component={HTML5ApplicationsOverviewPage} />
            <Route exact path="/html5-applications/flash-cards" component={HTML5ApplicationsFlashCards} />
            <Route exact path="/html5-applications/single-page" component={HTML5ApplicationsSinglePage} />
            <Route exact path="/html5-applications/practice-test" component={HTML5ApplicationsPracticeTest} />
            <Route exact path="/login" component={LoginPageContainer} />
            <Route exact path="/sign-up" component={SignUpPageContainer} />
            <Route exact path="/future-plans" component={ComingSoonPage} />
            <Route exact path="/admin" component={AdminPageContainer} />
          </div>
        </div>
      </ScrollToTop>
    </Router>
  );
}

const ConnectedApp = () => (
  <Provider store={store}>
    <CurrentUserResolver>
      <AuthGatekeeper>
        <AutoSignInUser />
      </AuthGatekeeper>
      <App />
    </CurrentUserResolver>
  </Provider>
);

export default ConnectedApp;
