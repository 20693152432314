import React, { useState } from 'react';
import { FirebaseContext } from '../Firebase';
import {connect} from 'react-redux';

const mapStateToProps = ({ adminGatekeeperState }) => adminGatekeeperState;

const AuthGatekeeper = props => {
  const {
    firebase,
    children,
  } = props;

  if(firebase.currentUser) {
    return children;
  } else {
    return null;
  }
};

const AuthGatekeeperWithFirebase = props => {
  return (
    <FirebaseContext.Consumer>
      {firebase =>
        <AuthGatekeeper {...props} firebase={firebase} />
      }
    </FirebaseContext.Consumer>
  );
};

export default connect(mapStateToProps)(AuthGatekeeperWithFirebase);
