import { connect } from 'react-redux';

const mapStateToProps = ({ loginPageState }) => {
  const { isLoggedIn} = loginPageState;
  return { isLoggedIn };
};

const UnauthGatekeeper = props => {
  const {
    isLoggedIn,
    children,
  } = props;

  if(isLoggedIn) {
    return null;
  } else {
    return children;
  }
};

export default connect(mapStateToProps)(UnauthGatekeeper);
