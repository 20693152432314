import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import FeedbackButtonContainer from '../../FeedbackButton/FeedbackButtonContainer';

const useStyles = makeStyles(theme =>({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  feedbackButtonContainer: {
    marginTop: theme.spacing(1),
  },
}));

const TestStepperCard = ({
  id: questionId,
  question,
  choices,
  updateTestAnswer,
  selectedAnswer,
}) => {
  const classes = useStyles();
  const feedbackMetaDescription = `test question with id:${questionId}, questionText: '${question}'`;

  const handleChange = (event) => {
    const answer = event.target.value;
    updateTestAnswer(questionId, answer);
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" component="h5">{question}</Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="answers"
          name="answers"
          className={classes.group}
          value={selectedAnswer}
          onChange={handleChange}
        >
          {
            Object.keys(choices).map((k) => (
              <FormControlLabel key={k} value={k} control={<Radio />} label={choices[k]} />
            ))
          }
        </RadioGroup>
      </FormControl>
      <Grid container direction="row-reverse" className={classes.feedbackContainer}>
        <FeedbackButtonContainer metaDescription={feedbackMetaDescription} />
      </Grid>
    </Paper>
  );
};

export default TestStepperCard;