import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles(theme => ({
  footer: {
    width: '100%',
    padding: theme.spacing(1),
    '& p': {
      fontSize: '.75em',
    },
  },
}));
const SMTLink = () => (
  <a href="https://smountaintech.com">
    South Mountain Technology llc.
  </a>
);

const Footer = () => {
  const classes = useStyles();

  return (<footer className={classes.footer}>
    <Grid container direction="column" alignItems="center" justify="center">
      <Grid item xs={8}>
        <p>
          Created by <SMTLink/>
          &nbsp;
          Copyright <SMTLink/>
        </p>
      </Grid>
    </Grid>
  </footer>);
};

export default Footer;