import React, { useEffect } from 'react';
import Loading from '../../Loading/Loading';
import Tabs from '@material-ui/core/Tabs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import FeedbackCheckList from './FeedbackCheckList';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: '1em',
  },
  header: {
    textAlign: 'center',
  },
  breadcrumbs: {
    display: 'flex',
    width: '100%',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

const FeedbackList = ({
  firebase,
  feedback,
  isLoading,
  feedbackIsLoaded,
  getFeedback,
  updateFeedback,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (!feedbackIsLoaded && !isLoading) {
      getFeedback(firebase);
    }
  });

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const updateFeedbackReviewed = (id, isReviewed) => {
    const feedbackToUpdate = feedback[id];
    const updatedFeedback = {
      ...feedbackToUpdate,
      isReviewed,
    };
    updateFeedback(firebase, id, updatedFeedback);
  };

  if(!feedbackIsLoaded || isLoading) return <Loading />;

  const feedbackIds = Object.keys(feedback);
  const unReviewedFeedback = feedbackIds.reduce((filtered, id) => {
    const f = feedback[id];
    if(!f.isReviewed) {
      return {
        ...filtered,
        [id]: f,
      };
    }

    return filtered;
  }, {});

  const reviewedFeedback = feedbackIds.reduce((filtered, id) => {
    const f = feedback[id];
    if(f.isReviewed) {
      return {
        ...filtered,
        [id]: f,
      };
    }

    return filtered;
  }, {});

  return (
    <Paper className={classes.paper}><Grid container direction="column">
      <Grid item xs={12} className={classes.breadcrumbs}>
        <Breadcrumbs aria-label="Breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Admin</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <h2 className={classes.header}><u>User Feedback</u></h2>
      </Grid>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Need Review" />
        <Tab label="Reviewed" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <FeedbackCheckList feedback={unReviewedFeedback} classes={classes} onCheckClicked={updateFeedbackReviewed}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FeedbackCheckList feedback={reviewedFeedback} classes={classes} onCheckClicked={updateFeedbackReviewed}/>
      </TabPanel>
    </Grid>
    </Paper>
  );
};

export default FeedbackList;