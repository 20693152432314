import React, { useState } from 'react';
import { withFirebase } from '../index';
import Loading from '../../Loading/Loading';

const CurrentUserResolver = ({ firebase, children }) => {
  const [isLoading, setIsLoading] = useState(true);

  firebase.auth.onAuthStateChanged(function() {
    setIsLoading(false)
  });

  if(isLoading) return <Loading />;

  return children;
};


export default withFirebase(CurrentUserResolver);