import ActionTypes from './admin-gatekeeper-action-types';

const initialState = {
  isAdminUser: false,
  hasDoneAdminCheck: false,
  isLoading: false,
};

const adminGatekeeperReducer = (state=initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case ActionTypes.ADMIN_GATEKEEPER_ADMIN_USERS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.ADMIN_GATEKEEPER_ADMIN_USERS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isAdminUser: payload.isAdminUser,
        hasDoneAdminCheck: true,
        isLoading: false,
      };
    case ActionTypes.ADMIN_GATEKEEPER_ADMIN_USERS_REQUEST_FAILED:
      return {
        ...state,
        isAdminUser: false,
        hasDoneAdminCheck: true,
        isLoading: false,
      };
    case ActionTypes.ADMIN_GATEKEEPER_RESET:
      return {
        ...state,
        isAdminUser: false,
        hasDoneAdminCheck: false,
      };
    default:
      return state;
  }
};

export default adminGatekeeperReducer;