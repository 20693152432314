import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TestStepper from '../../TestStepper/TestStepper';
import {
  fetchPracticeTest,
  updateTestAnswer,
  completeTest,
  resetTest,
  saveTest,
  loadSavedTest,
} from './state/practice-test-actions';
import TestOverview from '../../TestOverview/TestOverview';
import { withFirebase } from '../../Firebase';
import Loading from '../../Loading/Loading';

const mapStateToProps = ({ practiceTestState, loginPageState }) => {
  const { isUserDataLoaded, userData, isLoggedIn, } = loginPageState;
  return {
    ...practiceTestState,
    userData,
    isUserDataLoaded,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPracticeTest,
  updateTestAnswer,
  completeTest,
  resetTest,
  saveTest,
  loadSavedTest,
}, dispatch);

const PracticeTestContainer = (props) => {
  const {
    isComplete,
    saveTest,
    resetTest,
    loadSavedTest,
    firebase,
    isUserDataLoaded,
    userData,
    fetchPracticeTest,
    isLoading,
    isLoggedIn,
  } = props;

  let { selectedAnswers } =  props;

  const saveTestWithFirebase = saveTest.bind(this, firebase);
  const resetTestWithFirebase = resetTest.bind(this, firebase);

  if(isLoggedIn && !isUserDataLoaded) return <Loading />;

  if(userData && userData.selectedAnswers) {
    if (isLoading) {
      fetchPracticeTest();
      return <Loading />
    } else {
      loadSavedTest(userData.selectedAnswers);
    }
  }


  if(isComplete) return (
    <TestOverview {...props}
                  selectedAnswers={selectedAnswers}
                  saveTest={saveTestWithFirebase}
                  resetTest={resetTestWithFirebase}
    />
  );

  return <TestStepper {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(PracticeTestContainer));
