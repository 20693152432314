import ActionTypes from './admin-page-action-types';

export const getFeedback = firebase => dispatch => {
  dispatch(feedbackRequested());

  firebase.getFeedback().then(feedback => {
    dispatch(feedbackRequestComplete(feedback));
  }).catch(() => dispatch(feedbackRequestFailed()));
};

export const updateFeedback = (firebase, id, feedback) => dispatch => {
  dispatch(feedbackUpdateSubmitted());
  dispatch(feedbackUpdateComplete(id, feedback));

  return firebase.updateFeedback(id, feedback)
    .catch(() => dispatch(feedbackUpdateFailed()));
};

const feedbackRequested = () => ({
  type: ActionTypes.ADMIN_PAGE_FEEDBACK_REQUESTED,
});

const feedbackRequestComplete = (feedback) => ({
  type: ActionTypes.ADMIN_PAGE_FEEDBACK_REQUEST_COMPLETE,
  payload: { feedback },
});

const feedbackRequestFailed = () => ({
  type: ActionTypes.ADMIN_PAGE_FEEDBACK_REQUEST_FAILED,
});

const feedbackUpdateSubmitted = () => ({
  type: ActionTypes.ADMIN_PAGE_FEEDBACK_UPDATE_SUBMITTED,
});

const feedbackUpdateComplete = (id, feedback) => ({
  type: ActionTypes.ADMIN_PAGE_FEEDBACK_UPDATE_COMPLETE,
  payload: {
    id,
    feedback,
  },
});
const feedbackUpdateFailed = () => ({
  type: ActionTypes.ADMIN_PAGE_FEEDBACK_UPDATE_FAILED,
});
