import React from 'react';
import OverviewPage from '../OverviewPage/OverviewPage';

const contentTitle = "HTML5 Applications";

const contentDescription = `
  HTML5 is the latest iteration of HTML and empowers developers to create amazing platform
  platform independent applications.  The HTML5 family included HTML5, CSS3, and
  the latest JavaScript APIs and syntax.  This material is based on the book
  <em> HTML5 Application Development Fundamentals</em> which is the book used to study for
  the <em>MTA Exam 98-375</em>.
`;


const HTML5ApplicationsOverviewPage = () => {
  return (
    <OverviewPage contentTitle={contentTitle}
                  contentDescription={contentDescription}
                  subpath="html5-applications"
    />
  );
};

export default HTML5ApplicationsOverviewPage;