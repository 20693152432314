import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => (<Grid container justify="center" alignItems="center">
  <Grid item>
    <CircularProgress variant="indeterminate" />
  </Grid>
</Grid>);

export default Loading;

